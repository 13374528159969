<template>
  <div class="mitgliederverwaltung-fristennueberwachung">
    <NavigationBar
      showBackBtn
      titel="Details"
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="start">
            <v-col cols="12" sm="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title class="subheading">
                      {{ data.titel }}
                    </v-card-title>
                    <v-card-subtitle>{{ data.beschreibung }}</v-card-subtitle>
                    <v-card-text>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-map-marker</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle
                          >{{ data.fristentyp }}
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-row>
                <v-col cols="12">
                  <deadline-detail-card
                    :deadline="data"
                    hideTitle
                  ></deadline-detail-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <Subheader
                icon="mdi-calendar-alert"
                title=" Fristen für diesen Wert"
              >
              </Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="headers"
                  :items="ablaufdaten"
                  item-key="meta.id"
                  :items-per-page="10"
                  class="elevation-2"
                  max-height="800px auto"
                ></v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import DeadlineDetailCard from "@/components/member-management/deadlines/DeadlineDetailCard.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import {
  MEMBER_MANAGEMENT_DEADLINES_update,
  MEMBER_MANAGEMENT_DEADLINES_delete,
} from "@/data/permission-types.js";

export default {
  name: "member-management-deadline-monitoring",
  components: {
    NavigationBar,
    Subheader,
    DeadlineDetailCard,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${MEMBER_MANAGEMENT_DEADLINES_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permissions: `${MEMBER_MANAGEMENT_DEADLINES_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],
      data: {
        titel: "Tauglichkeit nach G26.3",
        beschreibung: "Lorem ipsum",
        fristentyp: "Jährlich",
        meta: { id: "1" },
        watchers: [
          { color: "success", value: 24 },
          { color: "warning", value: 5 },
          { color: "error", value: 0 },
        ],
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Ablaufdatum", value: "ablaufdatum" },
        { text: "Status", value: "status" },
      ],
    };
  },
  computed: {
    ablaufdaten() {
      return [
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
      ];
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "fristen", data: this.fristen }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    deleteItem() {},
  },
};
</script>
