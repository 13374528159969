<template>
  <div>
    <TippCard hintID="Mitgliederverwaltung_Fristenüberwachung_1_V1" text="Text">
    </TippCard>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="start">
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                item-key="meta.id"
                :items-per-page="10"
                sort-by="ablaufdatum"
                :sort-desc="true"
                :group-by.sync="groupBy"
                :group-desc.sync="groupDesc"
                class="elevation-1 rounded"
                max-height="800px auto"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <router-view />
  </div>
</template>

<script>
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";

export default {
  name: "member-management-deadline-monitoring",
  components: {
    SupportTools,
    TippCard,
  },
  data() {
    return {
      groupBy: "ablaufdatum",
      groupDesc: true,
      headers: [
        { text: "Personalnummer", value: "name" },
        { text: "Dienstgrad", value: "name" },
        { text: "Vorname", value: "name" },
        { text: "Nachname", value: "name" },
        { text: "Geburtsdatum", value: "birthday" },
        { text: "Letzte TÜV", value: "birthday" },
        { text: "Nächste TÜV", value: "birthday" },
        { text: "Einschränkungen", value: "status" },
      ],
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "deadlines", data: this.deadlines }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
  },
};
</script>
